import React from "react";
import {Layout, Menu, Breadcrumb} from 'antd';
import {
    DesktopOutlined,
    PieChartOutlined,
    FileOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';
import './Base.css';
import {MenuFoldOutlined, MenuUnfoldOutlined, ShoppingFilled} from "@ant-design/icons/lib";
import {Row} from "../../Core/Layout/Row";
import Button from "../../Core/Button";

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;


const Base = (props) => {
    const [collapsed, setCollapsed] = React.useState(false)
    const onCollapse = React.useCallback((value) => {
        console.log(value);
        setCollapsed(value);
    }, [collapsed])

    return <Layout style={{minHeight: '100vh'}}>

        <Sider trigger={null} collapsedWidth={0} breakpoint="md" width="250px" collapsed={collapsed}
               onCollapse={onCollapse}>
            <div className="logo">
            </div>
            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                <Menu.Item key="1" icon={<ShoppingFilled/>}>
                    Productos
                </Menu.Item>
            </Menu>
        </Sider>
        <Layout className="site-layout">
            <Header className="header">
                <Row>
                    <Row middle flex={1}>
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: () => setCollapsed(!collapsed),
                        })}
                    </Row>
                    <Row middle right flex={1}>
                        <Button color={'white'}  type={"text"}>Sign Out</Button>
                    </Row>
                </Row>
            </Header>
            <Content style={{margin: '32px 16px'}}>
                {
                    props.children
                }
            </Content>
            <Footer style={{textAlign: 'center'}}></Footer>
        </Layout>
    </Layout>
}

export default Base;
