import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from './Reducers';
import thunk from "redux-thunk";
import {ProductsReducerState} from "./Reducers/products";
import {ProductService} from "../Services/Product.service";
import {AuthService} from "../Services/Auth.service";
import {AuthReducerState} from "./Reducers/auth";

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type API_SERVICES = {
    product: ProductService,
    auth: AuthService,
}

const services: API_SERVICES = {
    product: new ProductService(),
    auth: new AuthService()
}

const enhancer = composeEnhancers(
    applyMiddleware(thunk.withExtraArgument(services)),
    // other store enhancers if any
);
/* eslint-disable no-underscore-dangle */
const getStore = () => createStore(rootReducer, enhancer);
/* eslint-enable */

export type State = {
    products: ProductsReducerState,
    auth: AuthReducerState
};

export default getStore;
