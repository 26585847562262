import styled from 'styled-components';
import {Common, CommonProps} from "./Common";

interface Props extends CommonProps {
    fit?: boolean;
    expand?: boolean;
    top?: boolean;
    middle?: boolean;
    bottom?: boolean;
    left?: boolean;
    center?: boolean;
    right?: boolean;
    stretch?: boolean;
}

export const Column = styled(Common)<Props>`
  flex-direction: column;
  ${(props: any) => props.fit && `flex: 1 1 auto;`};
  ${(props: any) => props.expand && `min-height: ${'100vh'}`};
  ${(props: any) => props.top && 'justify-content: flex-start'};
  ${(props: any) => props.middle && 'justify-content: center'};
  ${(props: any) => props.bottom && 'justify-content: flex-end'};
  ${(props: any) => props.left && 'align-items: flex-start'};
  ${(props: any) => props.center && 'align-items: center'};
  ${(props: any) => props.right && 'align-items: flex-end'};
  ${(props: any) => props.stretch && `width: 100%`};
`;
