interface Action {
    request: string;
    success: string;
    fail: string;

    [key: string]: string;
}

const generateAction = (name, extra = {}): Action => {
    return {
        request: `${name}_REQUEST`,
        success: `${name}_SUCCESS`,
        fail: `${name}_FAIL`,
        ...extra
    }
};

export {generateAction}
