import * as _ from 'lodash';
import {FlattenSimpleInterpolation} from "styled-components";

export const colorList = {
    blue: {
        default: '#17B4FE',
        hover: '#00ABFC',
        0.5: '#8BD9FF',
        0.3: '#D1F0FF',
    },
    gold: {
        default: '#FEAF17',
        0.5: '#FFD78B',
        0.3: '#FFEFD1',
    },
    green: {
        default: '#0EC878',
        0.5: '#87E3BB',
        0.3: '#CFF4E4',
    },
    magenta: {
        default: '#FE17F5',
        0.5: '#FF8BFA',
        0.3: '#FFD1FD',
    },
    red: {
        default: '#F0441C',
        0.5: '#F7A18D',
        0.3: '#FCDAD2',
    },
    salmon: {
        default: '#FF617D',
        0.5: '#FFAFBE',
        0.3: '#FFDFE5',
    },
    yellow: {
        default: '#FFDB29',
        0.5: '#FFED94',
        0.3: '#FFF8D4',
    },
    purple: {
        default: '#974FFF',
        0.5: '#CBA7FF',
        0.3: '#EADCFF',
    },
    teal: {
        default: '#1EF4A7',
        0.5: '#8EF9D2',
        0.3: '#BCFCE5',
    },
    black: {
        default: '#000000',
        hover: 'rgba(0, 0, 0, 0.8)',
    },
    white: {
        default: '#FFFFFF',
        hover: '#F5F5F5',
    },
    gray: {
        default: '#9E9E9E',
        0.9: '#212121',
        0.8: '#424242',
        0.7: '#616161',
        0.6: '#757575',
        0.5: '#9E9E9E',
        0.4: '#BDBDBD',
        0.3: '#E0E0E0',
        0.2: '#EEEEEE',
        0.1: '#F5F5F5',
        0.05: '#FAFAFA',
    },
};

export const getTxtColorByBkg = (bkg, fade = null) => {
    let lightText = [
        'blue',
        'gold',
        'green',
        'magenta',
        'red',
        'salmon',
        'purple',
        'black',
        'gray-0.9',
        'gray-0.8',
        'gray-0.7',
        'gray-0.6',
    ];
    let selectedBkg = fade ? `${bkg}-${fade}` : bkg;
    return lightText.includes(selectedBkg) ? 'white' : 'black';
};

export const Color = (color, fade = null) => {
    let newColor = colorList[color] ? colorList[color].default : null;
    if (fade && colorList[color] && colorList[color][fade]) {
        newColor = colorList[color][fade];
    }
    return newColor;
};

export enum Breakpoints {
    xs = `xs`,
    sm = `sm`,
    md = `md`,
    lg = `lg`,
    xl = `xl`,
}

export const breakpoint = {
    xs: {min: null, max: 575.98},
    sm: {min: 576, max: 767.98},
    md: {min: 768, max: 991.98},
    lg: {min: 992, max: 1199.98},
    xl: {min: 1200, max: null},
};

export const device: any = {
    xsMin: ``,
    smMin: `(min-width: ${breakpoint.sm.min}px)`,
    mdMin: `(min-width: ${breakpoint.md.min}px)`,
    lgMin: `(min-width: ${breakpoint.lg.min}px)`,
    xlMin: `(min-width: ${breakpoint.xl.min}px)`,

    xsMax: `(max-width: ${breakpoint.xs.max}px)`,
    smMax: `(max-width: ${breakpoint.sm.max}px)`,
    mdMax: `(max-width: ${breakpoint.md.max}px)`,
    lgMax: `(max-width: ${breakpoint.lg.max}px)`,
    xlMax: ``,

    get xs() {
        return this.xsMax;
    },
    get sm() {
        return this.smMin + ` and ` + this.smMax;
    },
    get md() {
        return this.mdMin + ` and ` + this.mdMax;
    },
    get lg() {
        return this.lgMin + ` and ` + this.lgMax;
    },
    get xl() {
        return this.xlMin;
    },

};


export const buildMediaQuery = (breakpoints: Breakpoints[], styles: FlattenSimpleInterpolation) => {
    return {
        breakpoints,
        styles
    }
}
