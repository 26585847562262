import {generateAction} from "./index";
import {API_SERVICES} from "../store";
import {message} from "antd";

export const LOGIN = generateAction('LOGIN');
export const SIGNUP = generateAction('SIGNUP');
export const SET_USER = 'SET_USER';

export const login = (email: string, password: string) => async (dispatch, getState, services: API_SERVICES) => {
    dispatch({type: LOGIN.request});
    try {
        await services.auth.login(email, password);
        dispatch({type: LOGIN.success});
    } catch (e) {
        message.error(e.message);
        dispatch({type: LOGIN.fail, payload: e});
    }
};

export const set_user = (user) => ({
    type: SET_USER,
    payload: user
})

