import React from "react";
import {Button, Col, Form, Input, InputNumber, Row, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons/lib";

type Props = {
    groupName: string;
    addButtonText: string;
    inputs: { label: string, placeholder: string, name: string, type: string, errorMessage: string, props?: any, required?: boolean }[]
}

export enum InputTypes {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER'
}

const itemLayout = {
    wrapperCol: {flex: 1},
};

const DynamicInputList = (props: Props) => {
    return <Form.List {...itemLayout} name={props.groupName}>
        {(fields, {add, remove}) => {
            return (
                <div>
                    {fields.map(field => (
                        <Row>
                            {
                                props.inputs.map((input) => {
                                    const InputComponent = input.type === InputTypes.TEXT ? Input : InputNumber;
                                    return <Col style={{marginLeft: 10, marginRight: 10}} flex={1}><Form.Item
                                        {...itemLayout}
                                        name={[field.name, input.name]}
                                        fieldKey={[field.fieldKey, input.name]}
                                        rules={[{
                                            required: input.required || true,
                                            message: input.errorMessage
                                        }]}
                                    >
                                        <InputComponent style={{width: '100%'}}
                                                        placeholder={input.placeholder} {...input.props}/>
                                    </Form.Item> </Col>
                                })
                            }
                            <Col flex={0}>
                                <MinusCircleOutlined
                                    onClick={() => {
                                        remove(field.name);
                                    }}
                                />
                            </Col>

                        </Row>
                    ))}

                    <Form.Item {...itemLayout}>
                        <Button
                            type="dashed"
                            onClick={() => {
                                add();
                            }}
                            block
                        >
                            <PlusOutlined/> {props.addButtonText}
                        </Button>
                    </Form.Item>
                </div>
            );
        }}
    </Form.List>
}

export default DynamicInputList;
