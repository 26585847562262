import React from "react";
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const AntIcon = (props) => <LoadingOutlined style={{fontSize: props.size || 25}} spin/>;

const Spinner = (props) => {
    return <Spin indicator={<AntIcon {...props}/>}/>
}

export default Spinner;
