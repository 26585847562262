import {combineReducers} from "redux";
import products from "./products";
import auth from "./auth";

export type ExtraBuildReducerOption = {
    type: string,
    action: (state: any, action: any) => any
}

export default combineReducers({
    products,
    auth
})
