import {auth} from "../firebase";

export class AuthService {

    login = async (email: string, password: string) => {
        return auth.signInWithEmailAndPassword(email, password).catch(e => {
            if (e.code) {
                if (e.code === `auth/user-not-found`) {
                    throw Error('The email was not found');
                }
            }
            throw Error(e.message || 'Internal server error');
        });
    }

}
