import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Modal, Row, Col} from 'antd';
import {Typography} from 'antd';
import Card from '../../Core/Card'
import Actions from "../../Core/Table/ActionsColumn";
import useTableColumns from "../../Core/Table/useTableColumns";
import {ColumnType} from "antd/lib/table";
import {ColumnsType} from "antd/es/table";
import {useDispatch, useSelector} from "react-redux";
import {ProductsReducerState} from "../../Store/Reducers/products";
import {State} from "../../Store/store";
import {getProducts} from "../../Store/Actions/products";
import Table from "../../Core/Table";

const {Title} = Typography;

const Products = (props) => {

    const [addEditModalVisible, addEditSetModalVisible] = useState(false);
    const [mode, setMode] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState({});


    const productsState = useSelector((state: State) => state.products);
    const dispatch = useDispatch();

    const onAddEditSubmit = useCallback(() => {
        if (mode === 'add') {

        } else {

        }

        setSelectedProduct({});
        addEditSetModalVisible(false);
    }, [selectedProduct, mode]);

    const onEdit = useCallback((product) => {
        setSelectedProduct(product);
        setMode('edit');
        addEditSetModalVisible(true);
    }, []);

    const onAdd = useCallback(() => {
        setMode('add');
        addEditSetModalVisible(true);
    }, [selectedProduct]);

    const onAddEditCancel = useCallback(() => {
        setSelectedProduct({});
        addEditSetModalVisible(false);
    }, [selectedProduct]);

    const [columns, setColumns] = useTableColumns({
        columns: [
            {
                title: 'Nombre',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Por Mayor',
                dataIndex: ['prices', 'mayor', 'value'],
                key: 'prices.mayor',
            },
            {
                title: 'Por Menor',
                dataIndex: ['prices', 'menor', 'value'],
                key: 'prices.menor',
            },
            {
                title: 'Stock',
                dataIndex: 'stock',
                key: 'stock',
            },
            Actions(onEdit, () => {
            }),
        ]
    });


    useEffect(() => {
        dispatch(
            getProducts()
        )
    }, []);


    return <Row>
        <Col flex={1}>
            <Card title="Productos" extra={
                <Button size={"large"} onClick={onAdd} type="primary">Crear Producto</Button>
            }>

                <Row>
                    <Col flex={1}>
                        <Table dataSource={productsState.data} columns={columns as ColumnsType<any>}
                               bordered
                               loading={productsState.isFetching}/>
                    </Col>
                </Row>
            </Card>
        </Col>
    </Row>
}


export default Products;
