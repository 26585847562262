import React, {Suspense, useEffect} from 'react';
import 'antd/dist/antd.less';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory
} from "react-router-dom";
import Products from "./Pages/Products/Products";
import Base from "./Pages/Base/Base";
import getStore from "./Store/store";
import {Provider, useDispatch} from "react-redux";
import AddEditProduct from "./Pages/AddEditProduct/AddEditProduct";
import {Col, Row} from "antd";
import Login from "./Pages/Login/Login";
import {auth} from "./firebase";
import {set_user} from "./Store/Actions/auth";
import Root from "./Pages/Root/Root";

const store = getStore();


// loading component for suspense fallback
const Loader = () => (
    <div className="App">
        <div>loading...</div>
    </div>
);

function App() {
    return <Provider store={store}>
        <Suspense fallback={<Loader/>}>
            <Router>
                <Root/>
            </Router>
        </Suspense>
    </Provider>
}

export default App;
