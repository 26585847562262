import styled from 'styled-components';
import {Color, getTxtColorByBkg, device, breakpoint} from '../../Styles/variables';
import * as _ from 'lodash';

const defaultPadding = 20;

const setPadding = padding => {
    return padding.toString().length && padding !== true
        ? padding
        : defaultPadding;
};

export interface CommonProps {
    fixed?: boolean;
    inline?: boolean;
    multiline?: boolean;
    hide?: string;
    show?: string;
    textCenter?: boolean;
    bkg?: string;
    fade?: string;
    border?: string;
    borderSize?: string;
    borderFade?: string;
    radius?: string;
    paddingAll?: string;
    paddingTop?: string;
    paddingLeft?: string;
    paddingRight?: string;
    paddingBottom?: string;
    paddingX?: string;
    paddingY?: string;
    marginAll?: string;
    marginTop?: string;
    marginLeft?: string;
    marginRight?: string;
    marginBottom?: string;
    marginX?: string;
    marginY?: string;
    flex?: string;
    width?: string;
    maxWidth?: string;
    shadow?: string;
    height?: string;
    maxHeight?: string;
    bkgImage?: string;
    only?: { breakpoints: string[], styles: any }[]

    [key: string]: any;
}

export const Common = styled.div<CommonProps>`
  ${props =>
    props.fixed &&
    `
    position: fixed;
`};
  ${props =>
    props.inline ? 'display: inline-flex' : 'display: flex'};
  color: ${props =>
    props.color ? Color(props.color) : getTxtColorByBkg(props.bkg, props.fade)};
  ${props => props.bkg && `background-color: ${Color(props.bkg, props.fade)}`};
  ${props =>
    props.border &&
    `border: ${props.borderSize || 1}px solid ${Color(
        props.border,
        props.borderFade ? props.borderFade : props.fade ? props.fade : 'default',
    )}`};
  ${props => props.radius && `border-radius: ${props.radius}px;`};
  ${props => props.paddingAll && `padding:${setPadding(props.paddingAll)}px`}
  ${props =>
    props.paddingTop && `padding-top:${setPadding(props.paddingTop)}px`};
  ${props =>
    props.paddingLeft && `padding-left:${setPadding(props.paddingLeft)}px`};
  ${props =>
    props.paddingRight && `padding-right:${setPadding(props.paddingRight)}px`};
  ${props =>
    props.paddingBottom &&
    `padding-bottom:${setPadding(props.paddingBottom)}px`};
  ${props =>
    props.paddingX &&
    `padding-left:${setPadding(props.paddingX)}px; 
    padding-right:${setPadding(props.paddingX)}px;`};
  ${props =>
    props.paddingY &&
    `padding-top:${setPadding(props.paddingY)}px; 
    padding-bottom:${setPadding(props.paddingY)}px;`};
  ${props => props.marginAll && `margin:${setPadding(props.marginAll)}px`};
  ${props => props.marginTop && `margin-top:${setPadding(props.marginTop)}px`};
  ${props =>
    props.marginLeft && `margin-left:${setPadding(props.marginLeft)}px`};
  ${props =>
    props.marginRight && `margin-right:${setPadding(props.marginRight)}px`};
  ${props =>
    props.marginBottom && `margin-bottom:${setPadding(props.marginBottom)}px`};
  ${props =>
    props.marginX &&
    `margin-left:${setPadding(props.marginX)}px; margin-right:${setPadding(
        props.marginX,
    )}px;`};
  ${props =>
    props.marginY &&
    `margin-top:${setPadding(props.marginY)}px; margin-bottom:${setPadding(
        props.marginY,
    )}px;`};
  ${props => props.flex && `flex: ${props.flex};`};
  ${props => props.width && `width: ${props.width};`};
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`};
  ${props => props.height && `height: ${props.height};`};
  ${props => props.maxHeight && `height: ${props.maxHeight};`};
  ${props => props.shadow && `box-shadow: ${props.shadow};`};
  ${props => props.multiline && `flex-wrap: wrap`};
  ${props =>
    props.hide &&
    `
    @media ${device[props.hide + `Max`]} {
      display: none;
    }
  `};
  ${props =>
    props.show &&
    `
    display: none;
    @media ${device[props.show + `Max`]} {
      display: flex;
    }
  `};
  ${props =>
    props.bkgImage &&
    `
    background-size: cover;
    background-image: url(${props.bkgImage});
  `};
  &.hover {
    ${`cursor: pointer `};
  }
  
  ${props => props.textCenter ? 'text-align:center;' : ''}
  
  
  ${props => {
    let string = ``;
    Object.keys(device).filter(e => !_.isFunction(device[e])).forEach(_device => {
        if (props[_device] && breakpoint[_device]) {
            string += `@media ${device[_device+`Min`]} {`;
            string += props[_device].toString();
            string += `}`
        }
    })
    if (props.only) {
        props.only.forEach((media: { breakpoints: string[], styles: any }) => {
            media.breakpoints.forEach((_breakpoint) => {
                if(breakpoint[_breakpoint]){
                    string += `@media ${device[_breakpoint]} {`;
                    string += media.styles.toString();
                    string += `}`
                }
            })
        })
    }
    return string;
}}
  
`;
