import {ADD_PRODUCT, GET_PRODUCTS, UPDATE_PRODUCT} from "../Actions/products";
import {buildReducer} from "./buildReducer";
import {Product} from "../../Models/Product.model";

export type ProductsReducerState = {
    data: Product[],
    isFetching: boolean,
    errorMessage: string,
}

const INITIAL_STATE: ProductsReducerState = {
    data: [],
    isFetching: false,
    errorMessage: null
};

const products = buildReducer(INITIAL_STATE, GET_PRODUCTS);


export default products;
