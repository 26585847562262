import React from "react";
import styled from "styled-components";
import {Table as T} from "antd";

interface Props {
    flex?:string;
}

const Table = styled(T)<Props>`
 ${props => props.flex && `flex: ${props.flex};`};
box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 0px rgba(60,64,67,.15);

.ant-table-pagination.ant-pagination {
    margin: 16px 16px;
}

`


export default Table;
