import {generateAction} from "./index";
import {API_SERVICES} from "../store";
import {Product} from "../../Models/Product.model";

export const ADD_PRODUCT = generateAction('ADD_PRODUCT');
export const UPDATE_PRODUCT = generateAction('UPDATE_PRODUCT');
export const GET_PRODUCTS = generateAction('GET_PRODUCTS');
export const DELETE_PRODUCT = generateAction('DELETE_PRODUCT');

export const getProducts = () => async (dispatch, getState, services: API_SERVICES) => {
    dispatch({type: GET_PRODUCTS.request});
    try {
        const products = await services.product.getProducts();
        dispatch({type: GET_PRODUCTS.success, payload: products});
    } catch (e) {
        dispatch({type: GET_PRODUCTS.fail, payload: e});
    }
};

export const addProduct = (product: Product) => async (dispatch, getState, services: API_SERVICES) => {
    dispatch({type: ADD_PRODUCT.request});
    try {
        await services.product.addProduct(product);
        dispatch({type: ADD_PRODUCT.success, payload: true});
    } catch (e) {
        dispatch({type: ADD_PRODUCT.fail, payload: e});
    }
};

export const updateProduct = (product: Product) => async (dispatch, getState, services: API_SERVICES) => {
    dispatch({type: UPDATE_PRODUCT.request});
    try {
        await services.product.updateProduct(product);
        dispatch({type: UPDATE_PRODUCT.success, payload: true});
    } catch (e) {
        dispatch({type: UPDATE_PRODUCT.fail, payload: e});
    }
};

