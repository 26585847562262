import React from "react";
import styled from "styled-components";
import {Form} from "antd";

interface Props {
    flex?: string;
}

const _F = styled(Form)<Props>`
 ${props => props.flex && `flex: ${props.flex};`};
`


export default _F;
