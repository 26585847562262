import React from "react";
import styled from "styled-components";
import {Card} from "antd";
import {CommonProps} from "./Layout/Common";

interface Props extends CommonProps{
    flex?:string;
}

const _Card = styled(Card)<Props>`
 ${props => props.flex && `flex: ${props.flex};`};
box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 0px rgba(60,64,67,.15);
`


export default _Card;
