import {ExtraBuildReducerOption} from "./index";

export const buildReducer = (initial_state = {}, actions, extra: ExtraBuildReducerOption[] = []) => {
    const INITIAL_STATE = {
        data: [],
        isFetching: false,
        errorMessage: null
    };
    const reducer = (state = {...INITIAL_STATE, ...initial_state}, action) => {
        switch (action.type) {
            case actions.request:
                return {
                    ...state,
                    isFetching: true
                };
            case actions.success:
                return {
                    ...state,
                    isFetching: false,
                    data: action.payload
                };
            case actions.fail:
                return {
                    ...state,
                    isFetching: false,
                    errorMessage: action.payload
                };
        }

        for(let i = 0; i<extra.length; i++) {
            const _e = extra[i];
            if (action.type === _e.type) {
                return _e.action(state, action);
            }
        }

        return state;

    };

    return reducer;
};
