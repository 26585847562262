import styled from 'styled-components';
import {Common, CommonProps} from "./Common";

interface Props extends CommonProps{
  fit?: boolean;
  expand?: boolean;
  top?: boolean;
  middle?: boolean;
  bottom?: boolean;
  left?: boolean;
  center?: boolean;
  right?: boolean;
  reverse?: boolean;
  noFlex?: boolean;
  spaceBetween?: boolean;
  truncate?: boolean;
}

export const Row = styled(Common)<Props>`
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  ${props => props.expand && `width: 100%`};
  ${props => props.fit && `flex: 1 1 auto`};
  ${props => props.noFlex && `flex: 0 1 auto`};
  ${props => props.top && 'align-items: flex-start'};
  ${props => props.middle && 'align-items: center'};
  ${props => props.bottom && 'align-items: flex-end'};
  ${props => props.left && 'justify-content: flex-start'};
  ${props => props.center && 'justify-content: center'};
  ${props => props.right && 'justify-content: flex-end'};
  ${props => props.spaceBetween && 'justify-content: space-between'};
  ${props =>
    props.truncate &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `};
`;
