import React, {useCallback} from "react";
import Form from "../../Core/Form";
import {Row} from "../../Core/Layout/Row";
import Title from "antd/es/typography/Title";
import Space from "../../Core/Space";
import {Input, message} from "antd";
import Button from "../../Core/Button";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../Store/Actions/auth";
import {State} from "../../Store/store";
import {Column} from "../../Core/Layout/Column";
import Spinner from "../../Core/Spinner";

const formInputStyle = {
    labelCol: {
        span: 24
    }
}

const info = () => {
    message.info('This is a normal message');
};

const CustomButton = styled(Button)`
  height:55px;
`;

const LoginForm = (props) => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const authSelector = useSelector((state: State) => state.auth);
    const [form] = Form.useForm();

    const onFinish = useCallback((values) => {
        console.log(values);
        dispatch(login(values.email, values.password));
    }, []);

    return <Form form={form} onFinish={onFinish} flex={"1"}>
        {
            authSelector?.loading ? <Column middle center flex={1}><Spinner size={100}/></Column> : <React.Fragment>
                <Row left marginBottom={30}>
                    <Title level={1}>{t('login.loginForm.titleText')}</Title>
                </Row>
                <Space size={30} direction={"vertical"}>
                    <Form.Item {...formInputStyle} name="email" label={t('login.loginForm.emailLabel')}
                               rules={[{required: true}]}>
                        <Input type={"email"} size={"large"}/>

                    </Form.Item>
                    <Form.Item {...formInputStyle} name="password" label={t('login.loginForm.passwordLabel')}
                               rules={[{required: true}]}>
                        <Input type={"password"} size={"large"}/>
                    </Form.Item>
                    <CustomButton color={"purple"} block size={"large"} htmlType="submit"
                                  type={"primary"}>{t('login.loginForm.loginButton')}</CustomButton>
                    <CustomButton block size={"large"}>{t('login.loginForm.signUpButton')}</CustomButton>
                </Space>
            </React.Fragment>
        }
    </Form>
}

export default LoginForm;
