import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyBorcjDAVWbwFWd_ZQiO68t2dzQ6fnkQzo",
    authDomain: "stock-manager-b22e8.firebaseapp.com",
    databaseURL: "https://stock-manager-b22e8.firebaseio.com",
    projectId: "stock-manager-b22e8",
    storageBucket: "stock-manager-b22e8.appspot.com",
    messagingSenderId: "1064376942804",
    appId: "1:1064376942804:web:5e6fc1bb83779ee8797b41",
    measurementId: "G-DT1B1SV982"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
