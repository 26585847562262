import {buildReducer} from "./buildReducer";
import {LOGIN, SET_USER} from "../Actions/auth";

export type AuthReducerState = {
    user: object,
    loading: boolean
}

const INITIAL_STATE: AuthReducerState = {
    user: null,
    loading: false,
};

const auth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN.request:
            return {...state, loading: true}
        case LOGIN.success:
            return {...state, loading: false}
        case LOGIN.fail:
            return {...state, loading: false}
        case SET_USER:
            return {...state, user: action.payload}
        default:
            return state
    }
}


export default auth;
