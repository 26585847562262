import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Button, Col, Form, Input, Modal, Row, Select, Space, Table} from 'antd';
import {Typography} from 'antd';
import Card from '../../Core/Card'
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons/lib";
import DynamicInputList, {InputTypes} from "../../Core/DynamicInput/DynamicInputList";
import * as _ from 'lodash';
import CurrencyProps from "../../Core/Utils/CurrencyProps";
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const {Text, Link} = Typography;
const {Option} = Select;
const {Title} = Typography;


const layout = {
    wrapperCol: {span: 24},
};

const labelStyle = {
    span: 24
}

const CustomEditor = styled(Editor)`
    .editorClassName{
      max-height:500px;
      overflow: scroll;
    }
`

const AddEditProduct = (props) => {
    const [form] = Form.useForm();
    const [stockTotal, setStockTotal] = useState(0);
    const [costTotal, setCostTotal] = useState(0);
    const [description, setDescription] = React.useState(EditorState.createEmpty());

    useEffect(() => {
        const html = '<p>Hey this <strong>editor</strong> rocks 😀</p>';
        const contentBlock = htmlToDraft(html);

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            form.setFieldsValue({description: editorState})
            setDescription(editorState)
        }
    }, [])

    const calculateStock = useCallback((stocks) => {
        if (!stocks) {
            setStockTotal(0);
            return;
        }
        const total = _.sum(stocks.map(s => Number(s?.amount || 0)));
        setStockTotal(total);

    }, []);

    const calculateCosts = useCallback((costs) => {
        if (!costs) {
            setCostTotal(0);
            return;
        }
        const total = _.sum(costs.map(s => Number(s?.cost || 0)));
        setCostTotal(total);

    }, []);


    const setEditorState = useCallback((editorState) => {
        setDescription(editorState)
    }, []);

    const formRef = useRef(null);

    const onFinish = useCallback((values) => {
        values.description = draftToHtml(convertToRaw(description.getCurrentContent()))
        console.log('termino el form', values)
    }, [description]);

    const onValuesChange = useCallback((changedValues, allValues) => {
        if (changedValues['stocks']) {
            calculateStock(allValues['stocks']);
        }
        if (changedValues['costs']) {
            calculateCosts(allValues['costs']);
        }
    }, [calculateCosts, calculateStock]);

    return (<Row>
            <Col flex={1}>
                <Form onValuesChange={onValuesChange} {...layout} ref={formRef} name="control-ref" form={form}
                      onFinish={onFinish}>
                    <Card title={"Crear Producto"}
                          extra={<Row justify={"end"}> <Button size={"large"} type="primary" htmlType="submit">
                              Crear Producto
                          </Button></Row>}>
                        <Row justify={"center"}>
                            <Col xs={24} md={24} lg={18} xl={12}>

                                <Form.Item labelCol={labelStyle} name="nombre" label="Nombre"
                                           rules={[{required: true}]}>
                                    <Input placeholder={"Nombre"}/>
                                </Form.Item>


                                <Space style={{flex: 1, display: 'flex'}} size={20} direction={"vertical"}>

                                    <Card title={"Descripcion"} style={{minHeight: 400}}>
                                        <Form.Item name="description">
                                            <CustomEditor
                                                editorState={description}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={setEditorState}
                                            />
                                        </Form.Item>
                                    </Card>

                                    <Card title={"Precios"}>
                                        <Row>
                                            <Col flex={1}>
                                                <DynamicInputList groupName={"prices"} addButtonText={"Agregar Precio"}
                                                                  inputs={[{
                                                                      name: 'type',
                                                                      label: '',
                                                                      placeholder: 'Tipo',
                                                                      type: InputTypes.TEXT,
                                                                      errorMessage: 'El tipo es invalido'
                                                                  },
                                                                      {
                                                                          name: 'price',
                                                                          label: '',
                                                                          placeholder: 'Precio',
                                                                          type: InputTypes.NUMBER,
                                                                          props: CurrencyProps(),
                                                                          errorMessage: 'El precio es invalido'
                                                                      }]}/>
                                            </Col>
                                        </Row>
                                    </Card>

                                    <Card title={`Stock: ${stockTotal}`}>
                                        <Row>
                                            <Col flex={1}>
                                                <DynamicInputList groupName={"stocks"} addButtonText={"Agregar Stock"}
                                                                  inputs={[{
                                                                      name: 'type',
                                                                      label: '',
                                                                      placeholder: 'Tipo',
                                                                      type: InputTypes.TEXT,
                                                                      errorMessage: 'El tipo es invalido'
                                                                  },
                                                                      {
                                                                          name: 'amount',
                                                                          label: '',
                                                                          placeholder: 'Cantidad',
                                                                          type: InputTypes.NUMBER,
                                                                          props: {min: 1},
                                                                          errorMessage: 'La cantidad es invalida'
                                                                      }]}/>
                                            </Col>
                                        </Row>
                                    </Card>

                                    <Card title={`Costos: $${costTotal}`}>
                                        <Row>
                                            <Col flex={1}>
                                                <DynamicInputList groupName={"costs"} addButtonText={"Agregar Costo"}
                                                                  inputs={[{
                                                                      name: 'task',
                                                                      label: '',
                                                                      placeholder: 'Tarea',
                                                                      type: InputTypes.TEXT,
                                                                      errorMessage: 'La tarea es invalida'
                                                                  },
                                                                      {
                                                                          name: 'cost',
                                                                          label: '',
                                                                          placeholder: 'Costo',
                                                                          type: InputTypes.NUMBER,
                                                                          props: CurrencyProps(),
                                                                          errorMessage: 'El costo es invalido'
                                                                      }]}/>
                                            </Col>
                                        </Row>
                                    </Card>

                                </Space>

                            </Col>
                        </Row>
                    </Card>
                </Form>
            </Col>
        </Row>
    );
}

export default AddEditProduct;
