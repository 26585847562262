import React, {Suspense, useEffect} from 'react'
import {Col, Row} from "antd";
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import Login from "../Login/Login";
import Base from "../Base/Base";
import Products from "../Products/Products";
import AddEditProduct from "../AddEditProduct/AddEditProduct";
import {auth} from "../../firebase";
import {set_user} from "../../Store/Actions/auth";
import {useDispatch} from "react-redux";

const Root = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        auth.onAuthStateChanged(function (user) {
            if (user) {
                const location = history.location.pathname;
                dispatch(set_user(user))
                if (location === '/login') {
                    history.push('/');
                }
            } else {
                // User is signed out.
                // ...
                dispatch(set_user(null))
                history.push('/login');
            }
        });
    }, [])

    return <Row>
        <Col flex={1}>
            <Switch>
                <Route path="/login">
                    <Login/>
                </Route>
                <Base>
                    <Route path="/products">
                        <Products/>
                    </Route>
                    <Route path="/add-product">
                        <AddEditProduct/>
                    </Route>
                </Base>
            </Switch>
        </Col>
    </Row>
}

export default Root;
