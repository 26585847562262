import React from "react";
import {Column} from "../../Core/Layout/Column";
import {css} from "styled-components";
import {Row} from "../../Core/Layout/Row";
import {Breakpoints, buildMediaQuery} from "../../Styles/variables";
import LoginForm from "./LoginForm";
import {useTranslation} from "react-i18next";


const media = [
    buildMediaQuery([Breakpoints.xs, Breakpoints.sm], css`width:100%;max-width: unset;`),
    buildMediaQuery([Breakpoints.md], css`width:60%`),
];

const Login = (props) => {
    return <Column expand bkg={"blue"}>
        <Row shadow={'0 0 15px rgba(0,0,0,.25)'} flex={"1"} width={"40%"} maxWidth={"640px"} only={media} bkg={"white"}>
            <Row paddingAll={100} middle center flex={"1"}>
                <LoginForm/>
            </Row>
        </Row>
    </Column>
};

export default Login;
