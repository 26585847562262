import React from "react";
import styled from "styled-components";
import {Card, Space} from "antd";

interface Props {
    flex?:string;
}


const _Card = styled(Space)<Props>`
 ${props => props.flex && `flex: ${props.flex};`};
 display:flex;
`


export default _Card;
