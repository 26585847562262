import {sleep} from "../Core/Utils/sleep";
import {Product} from "../Models/Product.model";


const DELAY = 1000;

export class ProductService {
    getProducts = async (): Promise<Product[]> => {
        const products: Product[] = [
            {
                name: 'Thora test 1',
                stock: 100,
                prices: {
                    mayor: {label: 'Por Mayor', value: 1000},
                    menor: {label: 'Por Menor', value: 2000}
                }
            },
            {
                name: 'Thora test 2',
                stock: 100,
                prices: {
                    mayor: {label: 'Por Mayor', value: 4000},
                    menor: {label: 'Por Menor', value: 6000}
                }
            },
            {
                name: 'Thora test 3',
                stock: 100,
                prices: {
                    mayor: {label: 'Por Mayor', value: 8000},
                    menor: {label: 'Por Menor', value: 10000}
                }
            }
        ] || [];
        await sleep(DELAY);
        return products;
    }
    addProduct = async (product: Product): Promise<Product[]> => {
        const products: Product[] = JSON.parse(localStorage.getItem('products')) || [];
        await sleep(DELAY);
        return products;
    }
    updateProduct = async (product: Product): Promise<Product[]> => {
        const products: Product[] = JSON.parse(localStorage.getItem('products')) || [];
        await sleep(DELAY);
        return products;
    }
}
