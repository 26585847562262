import React from 'react';
import {Popconfirm, Row, Col} from "antd";

const Actions = (onEdit, onDelete) => ({
    title: 'Acciones',
    dataIndex: '',
    key: 'x',
    width: '10%',
    isActions: true,
    render: () => <Row>
        <Col flex={1}><a onClick={onEdit}>Editar</a></Col>
        <Col flex={1}>
            <Popconfirm onConfirm={onDelete} title={"Seguro que quieres eliminar?"}>
                <a>Eliminar</a>
            </Popconfirm>
        </Col>
    </Row>
    ,
});

export default Actions;
