import React from "react";
import styled from "styled-components";
import {Button as B} from "antd";
import {Color, getTxtColorByBkg} from "../Styles/variables";

interface Props {
    flex?: string;
    color?: string;
}

const Button = styled(B)<Props>`
 ${props => props.flex && `flex: ${props.flex};`};
 ${props => props.color && props.type && props.type === "primary" && `
    background-color:${Color(props.color)};
    border-color:${Color(props.color)};
    color:${getTxtColorByBkg(props.color)};
    &:hover{
        background-color:${Color(props.color, 0.5)};
        border-color:${Color(props.color, 0.5)};
        color:${getTxtColorByBkg(props.color)};
    }
    &:focus{
        background-color:${Color(props.color, 0.5)};
        border-color:${Color(props.color, 0.5)};
        color:${getTxtColorByBkg(props.color)};
    }
  `};
 
  ${props => props.color && props?.type !== "primary" && `
    ${props.type !== "text" && props.type !== "link" ? `border-color:${Color(props.color)};` : ``}
    color:${Color(props.color)};
    &:hover{
        ${props.type !== "text" && props.type !== "link" ? `border-color:${Color(props.color, 0.5)};` : ``}
        color:${Color(props.color, 0.5)};
    }
    &:focus{
        ${props.type !== "text" && props.type !== "link" ? `border-color:${Color(props.color, 0.5)};` : ``}
        color:${Color(props.color, 0.5)};
    }
  `};
 
`


export default Button;
